<template>
  <div class="bills-content">
<!--    <a-row>-->
<!--      <a-col :span="16">-->
<!--        <h4 class="text-primary"><font-awesome-icon icon="file-invoice-dollar" /> Счета</h4>-->
<!--      </a-col>-->
<!--      <a-col :span="8" class="text-right">-->
<!--        <a-button :loading="transactionsLoading" @click="getTransactions"><a-icon type="reload" /> Обновить</a-button>-->
<!--      </a-col>-->
<!--    </a-row>-->
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <span class="h5 mb-0"><a-icon type="tags" /> Список промо-кодов: {{ groupObj.name }}
          <div v-if="groupObj.name" class="h6 text-gray-6 font-weight-normal font-size-14 mt-3 ml-4">
            Свободно: <a-icon type="tags" class="text-success"/> {{ groupObj.counter.free }} |
            Зарезервировано: <a-icon type="tags" class="text-warning"/> {{ groupObj.counter.reserved }} |
            Активировано: <a-icon type="tags" class="text-danger"/> {{ groupObj.counter.activated }}
          </div>
        </span>
        <div>
          <a-button type="primary" @click="showAddPromoModal" class="mr-2">
            <a-icon type="tag" /> Выпуск
          </a-button>
          <a-button class="mr-2" :loading="promoLoading" @click="fetch"><a-icon type="reload" /> Обновить</a-button>
          <a-tooltip title="Очистить группу" placement="bottom">
            <a-popover v-model="clearPopOverVisible" title="Очистка промокодов" trigger="click" placement="bottomRight">
              <template slot="content" style="width: 200px;">
                <a-popconfirm
                  title="Удалить все свободные промо-коды?"
                  @confirm="clearGroup('free')"
                  placement="bottom"
                >
                  <a-button type="link" size="small" block><a-icon type="delete" /> Только свободные</a-button><br/>
                </a-popconfirm>
                <a-popconfirm
                  title="Удалить промо-коды в резерве?"
                  @confirm="clearGroup('reserved')"
                  placement="bottom"
                >
                  <a-button type="link" class="mt-2" size="small" block><a-icon type="delete" /> Только в резерве</a-button><br/>
                </a-popconfirm>
                <a-popconfirm
                  title="Удалить все неактивированные?"
                  @confirm="clearGroup('not-activated')"
                  placement="bottom"
                >
                  <a-button type="link" class="mt-2" size="small" block><a-icon type="delete" /> Все неактивированные</a-button>
                </a-popconfirm>
              </template>
              <a-button type="danger" >
                <a-icon type="tag" /> Очистить
              </a-button>
            </a-popover>
          </a-tooltip>
        </div>
      </div>
      <a-table
        :columns="promoColumns"
        :dataSource="promoData"
        :pagination="pagination"
        :loading="promoLoading"
        @change="handleTableChange"
        >
        <span slot="code" slot-scope="code">
          <span> <a-icon type="tag" /> {{ code }} </span>
        </span>
        <span slot="login" slot-scope="login">
          <span v-if="login"> <a-icon type="user" /> {{ login }} </span>
        </span>
        <span slot="reserved" slot-scope="text, record">
          <span>
            <span v-if="(record.is_reserved === 1 && record.is_activated === 0)">
              <span v-if="record.confirmed === 1" class="text-warning">
                  <a-tooltip title="В резерве, разрешен к активации" placement="bottom">
                  <a-icon type="tag" theme="filled" class="font-size-24" />
                </a-tooltip>
              </span>
              <span v-else class="text-gray-6">
                  <a-tooltip title="В резерве, не подтвержден" placement="bottom">
                  <a-icon type="tag" theme="filled" class="font-size-24" />
                </a-tooltip>
              </span>
            </span>
            <span v-if="(record.is_activated === 1)" class="text-danger">
              <a-tooltip title="Активирован" placement="bottom">
                <a-icon type="tag" theme="filled" class="font-size-24" />
              </a-tooltip>
            </span>
            <span v-if="(record.is_reserved === 0 && record.is_activated === 0)" class="text-success">
              <a-tooltip title="Свободен" placement="bottom">
                <a-icon type="tag" theme="filled" class="font-size-24" />
              </a-tooltip>
            </span>
          </span>
        </span>
        <span slot="currency" slot-scope="currency">
          <span v-if="currency">{{ currency.name }}</span>
        </span>
        <span slot="amount" slot-scope="amount, record">
          <span v-if="amount">{{ amount }} {{ record.currency.abbr }}</span>
        </span>
        <span slot="amount_usd" slot-scope="amount_usd">
          <span v-if="amount_usd">{{ amount_usd }} $</span>
        </span>
        <span slot="reserved_at" slot-scope="reserved_at">
          <span v-if="reserved_at">{{ $moment.parseZone(reserved_at).format('DD.MM.YY HH:mm') }}</span>
        </span>
        <span slot="activated_at" slot-scope="activated_at">
          <span v-if="activated_at">{{ $moment.parseZone(activated_at).format('DD.MM.YY HH:mm') }}</span>
        </span>
        <span slot="operation" slot-scope="text, record">
          <div class="text-right">
<!--            <span class="ml-3">-->
<!--              <a-tooltip title="Редактировать" placement="bottom">-->
<!--                <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon type="edit" theme="filled" /></a>-->
<!--              </a-tooltip>-->
<!--            </span>-->
            <span class="ml-3">
              <a-tooltip title="Удалить" placement="bottom">
                <a-popconfirm
                  title="Удалить промо-код?"
                  @confirm="deleteCode(record)"
                  placement="left"
                >
                  <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
                </a-popconfirm>
              </a-tooltip>
            </span>
          </div>
        </span>
      </a-table>
    </a-card>
    <a-modal v-model="addPromoModal"
             title="Добавить промокод"
             :footer="null"
             class="add-promo-modal"
             width="520px"
             :destroyOnClose="true"
    >
      <add-promocode-component
        @closeModalFunction="closeAddPromoModal"
        @submitModalFunction="addPromoSubmit" />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import moment from 'moment-timezone'
import addPromocodeComponent from './modals/addPromocodeModal'

const promoColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: 'Промо-код',
    dataIndex: 'code',
    key: 'code',
    scopedSlots: { customRender: 'code' },
  },
  // {
  //   title: 'Сумма',
  //   dataIndex: 'amount',
  //   key: 'amount',
  //   sorter: (a, b) => a.amount - b.amount,
  //   scopedSlots: { customRender: 'amount' },
  // },
  {
    title: 'Статус',
    dataIndex: 'is_reserved',
    key: 'is_reserved',
    width: '8%',
    scopedSlots: { customRender: 'reserved' },
  },
  {
    title: 'Пользователь',
    dataIndex: 'login',
    key: 'login',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Валюта',
    dataIndex: 'currency',
    key: 'currency',
    width: '10%',
    scopedSlots: { customRender: 'currency' },
  },
  {
    title: 'Сумма',
    dataIndex: 'amount',
    key: 'amount',
    width: '10%',
    sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Сумма в USD',
    dataIndex: 'amount_usd',
    key: 'amount_usd',
    width: '10%',
    sorter: (a, b) => a.amount_usd - b.amount_usd,
    scopedSlots: { customRender: 'amount_usd' },
  },
  {
    title: 'Дата резерва',
    dataIndex: 'reserved_at',
    key: 'reserved_at',
    sorter: (a, b) => a.reserved_at - b.reserved_at,
    scopedSlots: { customRender: 'reserved_at' },
  },
  {
    title: 'Дата активации',
    dataIndex: 'activated_at',
    key: 'activated_at',
    sorter: (a, b) => a.activated_at - b.activated_at,
    scopedSlots: { customRender: 'activated_at' },
  },
  {
    title: 'Действие',
    dataIndex: 'operation',
    align: 'right',
    width: '10%',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'promocode-list',
  props: ['groupId'],
  components: {
    addPromocodeComponent,
  },
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      promoData: [],
      groupObj: [],
      promoColumns,
      moment,
      pagination: { pageSize: 25 },
      searchField: 'code',
      searchValue: '',
      promoLoading: false,
      clearPopOverVisible: false,
      addPromoLoading: false,
      addPromoModal: false,
      clearGroupForm: {
        type: '',
      },
    }
  },
  mounted () {
    // this.getPromoList()
    this.getGroup()
    this.fetch()
  },
  methods: {
    showAddPromoModal() {
      this.addPromoModal = true
    },
    closeAddPromoModal() {
      //
      this.addPromoModal = false
    },
    addPromoSubmit() {
      //
    },
    deleteCode(code) {
      const url = '/admin/promocodes/' + code.id
      apiClient.delete(url).then((response) => {
        console.log(response.data)
        this.$notification.success({
          message: 'Промокод удален',
          description: code.code,
        })
        const accIndex = this.promoData.findIndex(obj => obj.id === code.id)
        this.promoData.splice(accIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при удалении промо-кода',
          description: error.message,
        })
      })
    },
    clearGroup(promoType) {
      this.clearGroupForm.type = promoType
      const url = '/admin/promocodes/groups/' + this.groupId + '/clear'
      apiClient.post(url, this.clearGroupForm).then((response) => {
        console.log(response.data)
        this.$notification.success({
          message: 'Очистка группы завершена',
          description: '',
        })
        this.fetch()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Ошибка при очистке группы',
          description: error.message,
        })
      })
      this.clearPopOverVisible = false
    },
    getPromoList() {
      this.promoLoading = true
      const url = '/admin/promocodes/list/' + this.groupId
      apiClient.get(url).then((response) => {
        this.promoData = response.data.data
        this.promoLoading = false
      }).catch(error => {
        console.log(error)
        this.promoLoading = false
      })
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.promoLoading = true
      const reqData = { results: 25, ...params }
      console.log(reqData)
      const url = '/admin/promocodes/list/' + this.groupId
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.promoLoading = false
        this.promoData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.promoLoading = false
      })
    },
    addPromoGenerateCode(n) {
      if (n > 0) {
        for (let i = 1; i <= n; i++) {
          const str = this.randomString(12) + '\n'
          this.addPromoForm.code += str
        }
      }
      // this.addPromoForm.code = this.randomString(10)
    },
    randomString(length) {
      let result = ' '
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    getGroup() {
      const url = '/admin/promocodes/groups/' + this.groupId
      apiClient.get(url).then((response) => {
        this.groupObj = response.data.data
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>

</style>
